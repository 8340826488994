.under-construction-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .under-construction-content {
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .under-construction-content h1 {
    font-size: 3rem;
    color: #333;
  }
  
  .under-construction-content p {
    font-size: 1.2rem;
    margin: 20px 0;
    color: #555;
  }
  
  .construction-image img {
    max-width: 300px;
    margin-top: 20px;
  }
  