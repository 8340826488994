/* Tutorial.css */

 
.tutorial-section {
    opacity:0;
    transition: opacity 1s ease-in-out;
    padding: 20px;
    /*background-color: #f9f9f9; */

    margin: 20px auto; /* Center the section horizontally */
    /*max-width: 800px; /* Limit the width */
  }

  .tutorial-section.visible {
    opacity: 1; /* Make visible */
  }

  .tutorial-content {
    text-align: center; /* Center the title */
  }
  
  .tutorial-content h2 {
    margin-bottom: 20px;
    font-size: 32px;
    color: #333;
    padding:10px;
    padding-top:0px;
  }
  
  .video-container {
    display: flex;
    justify-content: center; /* Center the video horizontally */
    align-items: center; /* Center the video vertically */
    height: 500px; /* Set a fixed height for the video container */
    border-radius: 8px;
    overflow: hidden;
    /*background-color: #000; /* Optional: background color for the video container */
  }
  
  .tutorial-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the container */
  }
  